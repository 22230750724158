<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-overlay
            :show="showOverlay"
            rounded="sm"
            class="d-flex flex-column"
          >
            <b-table
              v-if="!toggleMaps"
              ref="refMitraListTable"
              :items="filterAllMitra"
              responsive
              :fields="tableColumns"
              empty-text="Data masih belum tersedia"
              class="position-relative"
              show-empty
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
            >

              <template #cell(nama)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="32"
                      badge
                      :src="data.item.fotoProfil"
                      :text="avatarText(data.item.nama)"
                      :variant="`light-primary`"
                    />
                  </template>
                  <router-link
                    class="font-weight-bold d-block text-nowrap"
                    :to="{ name: 'DetailMitra', params: { id: data.item.no_hp } }"
                    target="_blank"
                  >
                    {{ data.item.nama }}
                  </router-link>
                  <small
                    class="text-muted"
                  >Mitra {{ vertikalMitra(data.item.layanan[0].vertikal) }}</small>
                </b-media>
              </template>
              <template #cell(no_hp)="data">
                <p class="m-0">
                  {{ data.value }}
                </p>
              </template>
              <template #cell(layanan)="data">
                <b-badge
                  v-for="item in data.item.layanan"
                  :key="item.vertikal"
                  v-b-popover.hover.top="`${item.nama_leader}`"
                  class="text-capitalize badge-mitra"
                  :class="{ 'bg-primary': item.leader !== null && item.leader !== '' }"
                >
                  {{ vertikalMitra(item.vertikal) }}
                  <!-- {{ areaMitra(item.area) }} -->
                </b-badge>
              </template>
              <template #cell(status_order)="data">
                <b-badge :variant="displayOrderStatus(data.value).variant">
                  {{ displayOrderStatus(data.value).display }}
                </b-badge>
              </template>
              <template #cell(status_aktif)="data">
                <b-badge :variant="statusMitra(data.value).variant">
                  {{ statusMitra(data.value).display }}
                </b-badge>
              </template>
              <template #cell(saldo)="data">
                <b-badge :variant="data.value < 20000 ? 'light-danger' : 'light-primary'">
                  {{ data.value }}
                </b-badge>
              </template>
            </b-table>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  // BFormInput,
  BTable,
  BMedia,
  BAvatar,
  // BButton,
  BOverlay,
  BBadge,
  // BDropdown,
  // BDropdownItem,
  // BDropdownDivider,
  // BSpinner,
  VBPopover,
  // BButtonGroup,
  // BSidebar,
  // VBToggle,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import regionJson from '@/db/region.json'
// import { db } from '@/firebase'
import permission from '@/permission'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // BFormInput,
    BTable,
    BMedia,
    BAvatar,
    // BButton,
    BOverlay,
    BBadge,
    // BDropdown,
    // BDropdownItem,
    // BDropdownDivider,
    // BSpinner,
    // VBPopover,
    // BButtonGroup,
    // BSidebar,
    // VBToggle,
  },
  directives: {
    'b-popover': VBPopover,
    // 'b-toggle': VBToggle,
  },

  data() {
    return {
      avatarText,
      allmitra: [],
      filterAllMitra: [],
      lastVisible: null,
      perPage: 40,
      pageAll: 100,
      indexSearch: [],
      showOverlay: true,
      toggleMaps: false,
      leaderData: '',
      region: regionJson,
      isSortDirDesc: false,
      sortBy: 'saldo',
      leaderList: [],
      statusFilter: { value: 0, label: 'Semua Status' },
      permission: permission.access,

      layananOptions: [
        { value: '0', label: 'Semua Layanan' },
        { value: 'vl01', label: 'Akupunktur' },
        { value: 'vl02', label: 'Auto' },
        { value: 'vl03', label: 'Barber' },
        { value: 'vl04', label: 'Bekam' },
        { value: 'vl05', label: 'Beauty' },
        { value: 'vl06', label: 'Cleaning' },
        { value: 'vl07', label: 'Massage' },
        { value: 'vl08', label: 'Cuci AC' },
      ],

      tableColumns: [
        { key: 'nama', label: 'Mitra', sortable: true },
        { key: 'no_hp', label: 'Nomor HP', sortable: true },
        { key: 'layanan', sortable: false },
        { key: 'status_order', label: 'status order', sortable: false },
        { key: 'status_aktif', label: 'status', sortable: false },
        { key: 'saldo', label: 'saldo', sortable: false },
        { key: 'aksi', label: '' },
      ],
    }
  },
  created() {
    // this.leaderData = JSON.parse(localStorage.getItem('userData'))
    // if (this.leaderData.permission.mitra.area[0] !== 'all') {
    //   this.leaderData.permission.mitra.area.forEach(area => {
    //     const layanan = area.split('-')[0]
    //     const region = area.split('-')[1]
    //     this.indexSearch.push({ area: region, leader: this.leaderData.leader_id, vertikal: layanan })
    //   })
    // }

    this.getAllMitra()
  },
  methods: {
    async getAllMitra() {
      this.showOverlay = true

      const options = {
        url: 'https://us-central1-mecare-life.cloudfunctions.net/getAllMitra',
        method: 'GET',
        data: {
          limit: this.perPage <= 900 ? 900 : this.perPage,
          status: this.statusFilter.value,
          lastVisible: null,
          regionLeader: this.indexSearch,
        },
      }

      await this.$axios(options)
        .then(res => {
          this.lastVisible = res.data.lastVisible
          this.allmitra = [...new Set(res.data.mitra)]
          this.filterAllMitra = [...new Set(res.data.mitra)]
        })
        .catch(() => {
          this.loading = false
          this.showOverlay = false
        })
      this.showOverlay = false
    },

    vertikalMitra(vl) {
      if (vl) {
        const vertikalVariant = {
          vl01: 'Akupunktur',
          vl02: 'Auto',
          vl03: 'Barber',
          vl04: 'Bekam',
          vl05: 'Beauty',
          vl06: 'Cleaning',
          vl07: 'Massage',
          vl08: 'Cuci AC',
        }
        return vertikalVariant[vl]
      }
      return 'Layanan Belum Diisi'
    },
    displayOrderStatus(statusOrder) {
      if (statusOrder === true) { return { variant: 'success', display: 'Orderan On' } }
      if (statusOrder === false) return { variant: 'danger', display: 'Orderan Off' }
      return { variant: 'warning', display: 'tidak diketahui' }
    },

    // areaMitra(area) {
    //   if (area) {
    //     const fromjson = regionJson.find(item => item.id === area)
    //     const region = fromjson.name.split(' ')
    //     region.shift()
    //     const kota = region.join(' ')
    //     console.log(fromjson)
    //     return kota.toLowerCase()
    //   }
    //   return 'Kota Tidak Tersedia'
    // },

    applyFilterLayanan() { /* eslint-disable arrow-body-style */
      this.showOverlay = true
      if (this.layananFilter.value === '0') {
        this.getMitra()
      } else {
        const filtered = this.listMitra.filter(item => {
          return item.layanan[0].vertikal === this.layananFilter.value
        })
        this.filteredMitra = [...new Set(filtered)]
        this.showOverlay = false
      }
    },
    statusMitra(status) {
      if (status === 1) return { variant: 'light-info', display: 'Sedang Diproses' }
      if (status === 2) return { variant: 'light-info', display: 'Sedang Diverifikasi' }
      if (status === 3) return { variant: 'light-danger', display: 'Ditolak' }
      if (status === 4) return { variant: 'light-primary', display: 'Aktif' }
      if (status === 5) return { variant: 'light-warning', display: 'Saldo Kurang' }
      if (status === 6) return { variant: 'light-warning', display: 'Harus Konseling' }
      if (status === 7) return { variant: 'light-warning', display: 'Perlu Retraining' }
      if (status === 8) return { variant: 'light-danger', display: 'Dinonaktifkan' }
      if (status === 9) return { variant: 'light-danger', display: 'Diblokir' }
      return { variant: 'light-secondary', display: 'tidak diketahui' }
    },

    // async setLeader(nohp) {
    //   await db.collection('mitra').doc(nohp).get().then(snap => {
    //     const arr = []
    //     let nomer = 0
    //     snap.data().layanan.forEach(el => {
    //       arr.push({
    //         label: `${this.vertikalMitra(el.vertikal)} - ${this.areaMitra(el.area).toUpperCase()}`,
    //         vertikal: el.vertikal,
    //         area: el.area,
    //         leader: el.leader,
    //         index: nomer,
    //       })
    //       nomer += 1
    //     })
    //     this.layananMitra = [...new Set(arr)]
    //     this.currentMitra = snap.data()
    //     this.$refs.myBottomSheet.open()
    //   })
    // },

    // getLeader() {
    //   db.collection('mecarehub').where('role', '==', 'leader').orderBy('nama', 'asc').get()
    //     .then(snapshot => {
    //       const arr = []
    //       snapshot.docs.forEach(doc => {
    //         const layananLeader = this.vertikalMitra(doc.data().access.mitra.area[0].split('-')[0])
    //         const areaLeader = this.areaMitra(doc.data().access.mitra.area[0].split('-')[1])
    //         arr.push({
    //           id: doc.id,
    //           label: `${doc.data().nama} - Leader ${layananLeader} ${areaLeader.toUpperCase()}`,
    //           area: doc.data().access.mitra.area[0].split('-')[1],
    //         })
    //       })
    //       this.leaderList = [...new Set(arr)]
    //     })
    // },

  },

}
</script>
